<template>
    <div class="c-white">
        <h1 style="text-align: center;font-size:26px;">立即登录</h1>
        <div class="m-t-48 m-l-60 m-r-60">
            <el-form :model="form" ref="form" :rules="rules" label-width="50px" class="form-box" label-position="top">
                <el-form-item label="账号" size="large" prop="username">
                    <el-input v-model="form.username" size="large" maxlength="20" clearable placeholder="请输入账号/手机号">
                        <template #prefix>
                            <i class="el-icon-user"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="密码" size="large" prop="password">
                    <el-input v-model="form.password" size="large" maxlength="20" type="password" show-password clearable placeholder="请输入密码">
                        <template #prefix>
                            <i class="el-icon-lock"></i>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="webid" size="large" prop="web_id">
                    <el-input v-model="form.web_id" size="large" maxlength="20" clearable placeholder="请输入webid">
                        <template #prefix>
                            <i class="el-icon-user"></i>
                        </template>
                    </el-input>
                </el-form-item>

                <el-form-item>
                    <slot name="cut"></slot>
                </el-form-item>
                <el-form-item>
                    <el-button type="warning" size="large" style="width: 100%" @click="onSubmit('form')">登录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { login,login2,reg,config,send } from '@/api'
export default {
    name: 'SignInRight',
    data() {
        return {
            form: {
                username: '',
                password: '',
                web_id:"",
                client_id: ''
            },
            rules: {
                 web_id: [
                    {
                        required: true,
                        message: 'web_id不能为空',
                        trigger: 'change'
                    }
                ],
                username: [
                    {
                        required: true,
                        message: '账号不能为空',
                        trigger: 'change'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '密码不能为空',
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    methods: {
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.form.client_id = localStorage.getItem('client_id');
                    localStorage.setItem("web_id",this.form['web_id'] );
                    login2(this.form, true).then(res => {
                        this.loading = false
                        window.localStorage.setItem('token', res.data.token)
                        this.$router.push({ path: '/chat' })
                        this.$forceUpdate()
                    }).catch(e => {
                        this.loading = false
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style scoped>
.form-box :deep(.el-form-item__label) {
    color: white;
}
:deep(.el-input__prefix){
    left:10px;
}
</style>
